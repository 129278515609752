export default {
  'menu.welcome': '欢迎',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.admin': '管理页',
  'menu.admin.sub-page': '二级管理页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register.result': '注册结果',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',
  "menu.main": '后台主页',
  "menu.system":"系统管理",
  "menu.system.group":"用户组管理",
  "menu.system.user":"用户管理",
  "menu.system.global_area":"地区管理",
  "menu.system.tags_cate":"标签分组",
  "menu.system.tags_list":"标签列表",
  "menu.system.follow_comment_disable":"跟评屏蔽",
  "menu.system.follow_comment_disable_group":"屏蔽分组",
  "menu.system.area_account": "验证账户",
  "menu.system.account_type": "账号类型",
  "menu.system.account_list": "账号列表",
  "menu.system.mobile_list": "号码列表",
  "menu.aweme": "云端抖音",
  "menu.aweme.account": "账户列表",
  "menu.aweme.device": "镜像设备",
  "menu.aweme.version": "版本管理",
  "menu.aweme.view": "浏览列表",
  "menu.aweme.comment_cate": "评论库组",
  "menu.aweme.comment": "我的评论库",
  "menu.aweme.myworks": "我的作品",
  "menu.aweme.mycomment": "我的评论",
  "menu.aweme.followlist": "作者列表",
  "menu.aweme.favoritelist": "喜欢列表",
  "menu.works": "作品管理",
  "menu.works.workscate": "作品类型",
  "menu.works.material": "作品素材",
  "menu.works.works_list": "作品列表",
  "menu.works.manage_task_down": "下载任务",
  "menu.works.manage_background_music": "TK背景音乐",
  "menu.tiktok": "Tiktok管理",
  "menu.tiktok.device": "设备列表",
  "menu.tiktok.account": "账户列表",
  "menu.tiktok.comment": "我的评论库",
  "menu.tiktok.comment_cate": "评论库组",
  "menu.tiktok.view": "浏览列表",
  "menu.tiktok.myworks": "我的作品",
  "menu.tiktok.mycomment": "我的评论",
  "menu.tiktok.favorite_user_list": "作者列表",
  "menu.tiktok.favoritelist": "喜欢列表",
  "menu.tiktok.area": "区域管理",
  "menu.tiktok.tk_version": "版本管理",
  "menu.tiktok.my_works_comment": "作品评论",

  "menu.live_interactive": "直播互动",
  "menu.live_interactive.device": "设备列表",
  "menu.live_interactive.cate": "分类列表",
  "menu.live_interactive.content": "内容列表",
  "menu.live_interactive.nickname": "昵称列表",
  "menu.live_interactive.nickcate": "昵称分组",

  "menu.h5_page": "H5页面",
  "menu.h5_page.ad": "广告列表",
  "menu.h5_page.cate": "分类列表",
  "menu.h5_page.page": "页面列表",


};
