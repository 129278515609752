// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/www/www/git_wwwroot/NewMedia/ClientManage/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "login",
            "path": "/user/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__login' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/User/login'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/layouts/BasicLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/",
                "redirect": "/welcome",
                "exact": true
              },
              {
                "name": "main",
                "icon": "smile",
                "path": "/main",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Main' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Main'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/system",
                "name": "system",
                "icon": "crown",
                "routes": [
                  {
                    "name": "group",
                    "icon": "smile",
                    "path": "/system/group",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__Group' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/System/Group'), loading: LoadingComponent}),
                    "authority": [
                      "superadmin"
                    ],
                    "exact": true
                  },
                  {
                    "name": "user",
                    "icon": "smile",
                    "path": "/system/userlist",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__UserList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/System/UserList'), loading: LoadingComponent}),
                    "authority": [
                      "superadmin",
                      "generaladmin"
                    ],
                    "exact": true
                  },
                  {
                    "name": "area_account",
                    "icon": "smile",
                    "path": "/system/area_account",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__AreaAccount' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/System/AreaAccount'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "global_area",
                    "icon": "smile",
                    "path": "/system/globalarea",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__GlobalArea' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/System/GlobalArea'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "tags_cate",
                    "icon": "smile",
                    "path": "/system/tagscatelist",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__TagsCateList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/System/TagsCateList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "tags_list",
                    "icon": "smile",
                    "path": "/system/tagslist",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__TagsList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/System/TagsList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "follow_comment_disable_group",
                    "icon": "smile",
                    "path": "/system/follow_comment_disable_group",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__FollowCommentDisableGroup' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/System/FollowCommentDisableGroup'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "follow_comment_disable",
                    "icon": "smile",
                    "path": "/system/follow_comment_disable",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__FollowCommentDisable' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/System/FollowCommentDisable'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "mobile_list",
                    "icon": "smile",
                    "path": "/system/mobile_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__MobileList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/System/MobileList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "account_type",
                    "icon": "smile",
                    "path": "/system/account_type",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__AccountType' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/System/AccountType'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "account_list",
                    "icon": "smile",
                    "path": "/system/account_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__AccountList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/System/AccountList'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/aweme",
                "name": "aweme",
                "icon": "crown",
                "routes": [
                  {
                    "name": "device",
                    "icon": "smile",
                    "path": "/aweme/device_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DouYin__DeviceList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/DouYin/DeviceList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "version",
                    "icon": "smile",
                    "path": "/aweme/manage_version",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DouYin__ManageVersion' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/DouYin/ManageVersion'), loading: LoadingComponent}),
                    "authority": [
                      "superadmin"
                    ],
                    "exact": true
                  },
                  {
                    "name": "account",
                    "icon": "smile",
                    "path": "/aweme/account",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DouYin__Account' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/DouYin/Account'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "view",
                    "icon": "smile",
                    "path": "/aweme/stats_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DouYin__StatsList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/DouYin/StatsList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "comment_cate",
                    "icon": "smile",
                    "path": "/aweme/comment_cate",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DouYin__CommentCate' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/DouYin/CommentCate'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "comment",
                    "icon": "smile",
                    "path": "/aweme/comment_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DouYin__CommentList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/DouYin/CommentList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "myworks",
                    "icon": "smile",
                    "path": "/aweme/myworks",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DouYin__MyWorks' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/DouYin/MyWorks'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "mycomment",
                    "icon": "smile",
                    "path": "/aweme/mycomment",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DouYin__MyComment' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/DouYin/MyComment'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "followlist",
                    "icon": "smile",
                    "path": "/aweme/followlist",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DouYin__FollowList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/DouYin/FollowList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "favoritelist",
                    "icon": "smile",
                    "path": "/aweme/favorite_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DouYin__FavoriteList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/DouYin/FavoriteList'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/tiktok",
                "name": "tiktok",
                "icon": "crown",
                "routes": [
                  {
                    "name": "device",
                    "icon": "smile",
                    "path": "/tiktok/device_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__DeviceList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/DeviceList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "area",
                    "icon": "smile",
                    "path": "/tiktok/area",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__Area' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/Area'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "tk_version",
                    "icon": "smile",
                    "path": "/tiktok/manage_version",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__ManageVersion' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/ManageVersion'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "account",
                    "icon": "smile",
                    "path": "/tiktok/account",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__Account' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/Account'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "view",
                    "icon": "smile",
                    "path": "/tiktok/stats_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__StatsList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/StatsList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "comment_cate",
                    "icon": "smile",
                    "path": "/tiktok/comment_cate",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__CommentCate' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/CommentCate'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "comment",
                    "icon": "smile",
                    "path": "/tiktok/comment_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__CommentList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/CommentList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "myworks",
                    "icon": "smile",
                    "path": "/tiktok/myworks",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__MyWorks' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/MyWorks'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "my_works_comment",
                    "icon": "smile",
                    "path": "/tiktok/my_works_comment",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__MyWorksComment' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/MyWorksComment'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "mycomment",
                    "icon": "smile",
                    "path": "/tiktok/mycomment",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__MyComment' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/MyComment'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "favorite_user_list",
                    "icon": "smile",
                    "path": "/tiktok/favorite_user_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__FavoriteUser' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/FavoriteUser'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "favoritelist",
                    "icon": "smile",
                    "path": "/tiktok/favorite_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tiktok__FavoriteList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Tiktok/FavoriteList'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/works",
                "name": "works",
                "icon": "crown",
                "routes": [
                  {
                    "name": "workscate",
                    "icon": "smile",
                    "path": "/works/workscate",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Works__WorksCate' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Works/WorksCate'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "material",
                    "icon": "smile",
                    "path": "/works/works_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Works__WorksList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Works/WorksList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "manage_task_down",
                    "icon": "smile",
                    "path": "/works/manage_task_down",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Works__ManageTaskDown' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Works/ManageTaskDown'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "manage_background_music",
                    "icon": "smile",
                    "path": "/works/tk_music_list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Works__TkMusicList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/Works/TkMusicList'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/live_interactive",
                "name": "live_interactive",
                "icon": "crown",
                "routes": [
                  {
                    "name": "cate",
                    "icon": "smile",
                    "path": "/live_interactive/cate",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LiveInteractive__Cate' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/LiveInteractive/Cate'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "content",
                    "icon": "smile",
                    "path": "/live_interactive/content",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LiveInteractive__ContentList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/LiveInteractive/ContentList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "nickcate",
                    "icon": "smile",
                    "path": "/live_interactive/nickcate",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LiveInteractive__NickCate' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/LiveInteractive/NickCate'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "nickname",
                    "icon": "smile",
                    "path": "/live_interactive/nickname",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LiveInteractive__NicknameList' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/LiveInteractive/NicknameList'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "device",
                    "icon": "smile",
                    "path": "/live_interactive/device",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LiveInteractive__Device' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/LiveInteractive/Device'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/h5_page",
                "name": "h5_page",
                "icon": "crown",
                "routes": [
                  {
                    "name": "ad",
                    "icon": "smile",
                    "path": "/h5_page/ad",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__H5Page__Ad' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/H5Page/Ad'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "cate",
                    "icon": "smile",
                    "path": "/h5_page/cate",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__H5Page__Cate' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/H5Page/Cate'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "page",
                    "icon": "smile",
                    "path": "/h5_page/page",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__H5Page__Page' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/H5Page/Page'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/www/www/git_wwwroot/NewMedia/ClientManage/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
