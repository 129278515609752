import request from '@/utils/request';
import {encrypt} from '@/utils/encryp';
export async function fakeAccountLogin(params) {
  try {
    const client_appid = localStorage.getItem('client_appid');
    const client_secret = localStorage.getItem('client_secret');
    const a = client_appid.substring(3,19);
    const b = client_secret.substring(5,21);
    const u = params.userName;
    const p = params.password;
    if(p!==undefined){
      const p_e = encrypt(p,a,b);
      params['password'] = p_e;
    }
    if(u!==undefined)
    {
      const u_e = encrypt(u,a,b);
      params['userName'] = u_e;
    }

  }
  catch(e){
    console.log( e );
  }
  return request(API_SERVER+'/api/login/account', {
    method: 'POST',
    data: params,
  });
}

