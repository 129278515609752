/** Request 网络请求工具 更详细的 api 文档: https://github.com/umijs/umi-request */
import { extend } from 'umi-request';
import { notification } from 'antd';
import cookies from 'js-cookie';
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};
/**
 * @zh-CN 异常处理程序
 * @en-US Exception handler
 */

const errorHandler = (error) => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    notification.error({
      message: `Request error ${status}: ${url}`,
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }

  return response;
};
/**
 * @en-US Configure the default parameters for request
 * @zh-CN 配置request请求时的默认参数
 */

const request = extend({
  errorHandler,
  // default error handling
  credentials: 'include', // Does the default request bring cookies
});

/**
 * 对于状态码实际是 200 的错误
 */
request.interceptors.response.use(async (response) => {
  const res = await response.clone().json();
  const code = res.code;

  if (code === "10002")
  {
    cookies.remove('client_uuid');
    cookies.remove('clent_uuid2');
    cookies.remove('csrftoken');
    cookies.remove('manage_id');
    cookies.remove('token');
    cookies.remove('group_id');
    cookies.remove('login_time');
    window.location.href = '/user/login';
    return false

  }
  else if (code === "20000")
  {
    const client_uuid = res.data.client_uuid;
    const time_out = res.data.time_out;
    const domain = res.data.domain;
    const csrftoken = res.data.csrftoken;
    const client_appid = res.data.client_appid;
    const client_secret = res.data.client_secret;
    const clent_uuid2 = res.data.clent_uuid2;
    const expires = new Date();
    expires.setDate(Date.now() + time_out);
    const other_config = {
      expires: 1,
      path: '/',
      domain: domain,
    };
    cookies.set('client_uuid', client_uuid, other_config);
    cookies.set('csrftoken', csrftoken, other_config);
    cookies.set('clent_uuid2', clent_uuid2, other_config);
    localStorage.setItem('client_appid', client_appid);
    localStorage.setItem('client_secret', client_secret);
  }
  else if(code === "10000")
  {

  }
  else if(code === "10003")
  {
    const manage_id = res.manage_id;
    const token = res.token;
    const group_id = res.group_id;
    const group_tag = res.group_tag;
    const domain = res.domain;
    const csrftoken = res.csrftoken;
    const login_time = res.login_time;
    const other_config = {
      expires: 1,
      path: '/',
      domain: domain,
    };
    cookies.set('manage_id', manage_id, other_config);
    cookies.set('token', token, other_config);
    cookies.set('group_id', group_id, other_config);
    cookies.set('csrftoken', csrftoken, other_config);
    cookies.set("login_time",login_time, other_config);
    localStorage.setItem('group_tag', group_tag);
    localStorage.setItem("antd-pro-authority", group_tag);
    window.location.href = '/main';
    return notification.success({
      description: res.msg,
      message: '信息提示',
    });
  }
  else
  {

     notification.error({
      description: res.msg,
      message: '信息提示',
    });
    return false

  }
  return response;
});
export default request;
